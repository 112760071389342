import React from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Breadcrumbs from "../../components/ui/breadcrumbs"

import pillar from "../../../static/images/pillars/pillar-3.jpg"

class RootIndex extends React.Component {
    render() {
        return (
            <Layout location={this.props.location}>
                <Seo title="Pillar 3" />
                <div className="container single-pillar">
                    <img src={pillar} alt="happy woman" />
                    <Breadcrumbs loc="pillars" subloc="pillar 3" />
                    <h1>Pillar 3</h1>
                    <h2>
                        Improve brain function to stave off cognitive decline,
                        <br />
                        depression and anxiety
                    </h2>
                    <p>
                        The idea that physical activity improves brain health is
                        well established by now. Exercise increases neurons in
                        the hippocampus which is essential for memory creation
                        and storage, while also improving thinking skills. In
                        older adults regular, physical activity helps slow the
                        usual loss of brain volume which may help to prevent age
                        related memory loss and possibly lower the risk of
                        dementia. Working out improves blood flow to the brain,
                        promotes cardiovascular health, reduces inflammation and
                        lowers levels of stress hormones.{" "}
                    </p>

                    <h3>Mental health benefits of exercising</h3>
                    <p>
                        People who exercise regularly tend to do so because it
                        gives them an enormous sense of overall wellbeing. They
                        feel more energetic throughout the day, sleep better at
                        night, have sharper memories and feel more relaxed and
                        positive about themselves and their lives. Regular
                        exercise can have a profoundly positive impact on
                        depression and anxiety. It also relieves stress,
                        improves memory, helps you sleep better and boosts your
                        overall mood!
                    </p>
                    <h3>Exercise against depression and anxiety</h3>
                    <p>
                        Studies show that exercise can treat mild to moderate
                        depression as effectively as antidepressant medication
                        but without the side effects, of course. In addition, to
                        relieving depression and anxiety symptoms, research
                        shows that maintaining an exercise schedule can prevent
                        you from relapsing. Exercise is a powerful fighter for
                        several reasons. Most importantly, it promotes all kinds
                        of changes in the brain, including neural growth,
                        reduced inflammation and new activity patterns that
                        promote feelings of calm and well being. It also
                        releases endorphins, powerful chemicals in your brain
                        that energize your spirits and make you feel good.
                        Finally exercise can also serve as distraction allowing
                        you to find some quiet time to break out of the cycle of
                        negative thoughts that feed depression and anxiety.{" "}
                    </p>
                    <Link className="button" to="/pillars/pillar-4/">
                        Next pillar
                    </Link>
                </div>
            </Layout>
        )
    }
}

export default RootIndex
